import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import TransIcon from '@static/icons/translate.svg';

import { Section, Container } from '@components/global';

import SingleBook from '@common/SingleBook';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'The Bandit Queen',
    image: 'debi.png',
    url:
      'https://www.amazon.com/dp/B06Y4MYSH2/ref=cm_sw_em_r_mt_dp_U_5I0gFb4Z29PAV',
    content: () => (
      <>
        A nobleman’s wife notices Profullo and thinks that this lovely girl will
        make a perfect wife for her son. What will happen now?
      </>
    ),
  },
  {
    title: 'Devdas',
    image: 'devdas.png',
    url:
      'https://www.amazon.com/dp/B00FIHJ3I0/ref=cm_sw_em_r_mt_dp_U_tK0gFb4N9FMB9',
    content: () => (
      <>
        The beautiful and attractive and heedless, Devdas feels that he is
        entitled to the world.
      </>
    ),
  },
  {
    title: 'Kopalkundala',
    image: 'kk.png',
    url:
      'https://www.amazon.com/dp/B00FAY6U3C/ref=cm_sw_em_r_mt_dp_U_MN0gFbZ18MR3T',
    content: () => (
      <>
        This is a historical adventure set during the reign of the Mughal
        Emperor Jehangir.
      </>
    ),
  },
  // {
  //   title: 'The Twelve Dancing Princesses',
  //   image: 'todd.jpg',
  //   content: () => <>Forthcoming...</>,
  // },
];

const Art = styled.figure`
  width: 100%;
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: top;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(min-content, max-content);

  grid-gap: 64px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;
  }
`;

const Faq = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="translations" accent>
        <Container>
          <h1 style={{ marginBottom: 40 }}>
            {' '}
            <img src={TransIcon} alt="link" />
            Translations
          </h1>
          <div>
            <Grid>
              {FAQS.map(({ title, image, url, content }) => {
                const img = data.allFile.edges.find(
                  ({ node }) => node.relativePath === image
                ).node;
                return (
                  <SingleBook
                    title={title}
                    url={url}
                    image={img.childImageSharp.fluid}
                    key={title}
                  >
                    {content()}
                  </SingleBook>
                );
              })}
            </Grid>
          </div>
        </Container>
      </Section>
    )}
  />
);

export default Faq;

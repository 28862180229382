import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import { Section, Container } from '@components/global';

import SingleBook from '@common/SingleBook';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'Arun Barun Kironmala',
    image: 'cover_inked.jpg',
    url:
      'https://www.amazon.com/dp/1691066761/ref=cm_sw_em_r_mt_dp_U_NLQgFbWX9G79K',
    content: () => <>Children's folk tale from India</>,
  },
  {
    title: 'Nilkomar and Lalkomar',
    image: 'fig5.png',
    url:
      'https://www.amazon.com/dp/B088LJJ9D2/ref=cm_sw_em_r_mt_dp_U_oMQgFbDBAKYAG',
    content: () => (
      <>
        Two brave brothers, one human and one part ogre, set out to free the
        world of the horrible monsters, the Khokoshes.
      </>
    ),
  },
  {
    title: 'My Little Ramayana',
    image: 'ramayana.png',
    url:
      'https://www.amazon.com/dp/1675230137/ref=cm_sw_em_r_mt_dp_U_KOQgFbV6YSZE3',
    content: () => <>An epic adventure story for children</>,
  },
  // {
  //   title: 'The Twelve Dancing Princesses',
  //   image: 'todd.jpg',
  //   content: () => <>Forthcoming...</>,
  // },
];

const Art = styled.figure`
  width: 100%;
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: top;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(min-content, max-content);

  grid-gap: 64px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;
  }
`;

const Faq = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="children's books">
        <Container>
          <h1 style={{ marginBottom: 40 }}>Stories for Children</h1>
          <div>
            <Grid>
              {FAQS.map(({ title, image, url, content }) => {
                const img = data.allFile.edges.find(
                  ({ node }) => node.relativePath === image
                ).node;
                return (
                  <SingleBook
                    title={title}
                    url={url}
                    image={img.childImageSharp.fluid}
                    key={title}
                  >
                    {content()}
                  </SingleBook>
                );
              })}
            </Grid>
          </div>
        </Container>
      </Section>
    )}
  />
);

export default Faq;

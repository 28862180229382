import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'How can I contact Devjani?',
    content: () => <>Contact Devjani at devjanibooks@gmail.com.</>,
  },
  {
    title:
      'I have an idea for a story or translation. How can I let Devjani know?',
    content: () => <>Send Devjani an email at devjanibooks@gmail.com!</>,
  },
  {
    title: 'Where are these stories from?',
    content: () => (
      <>
        These are a collection of stories mostly from the region of India called
        West Bengal.
      </>
    ),
  },

  {
    title: 'Does Devjani have a personal blog?',
    content: () => (
      <>
        Why, yes she does! Keep up-to-date with other news, writings and audio
        recordings at{' '}
        <ExternalLink href="https://ranijani.com/">Rani Jani</ExternalLink>.
      </>
    ),
  },
  {
    title: 'What is a khokosh?',
    content: () => <>A very scary, scaly monster! </>,
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
